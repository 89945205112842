export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"}],"link":[{"rel":"stylesheet","href":"https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css"},{"rel":"stylesheet","href":"https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css"},{"rel":"stylesheet","href":"https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css"},{"rel":"stylesheet","href":"https://cdn.jsdelivr.net/gh/lipis/flag-icons@6.9.2/css/flag-icons.min.css"},{"rel":"stylesheet","href":"https://cdn.datatables.net/1.13.6/css/jquery.dataTables.css"},{"rel":"stylesheet","href":"https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.9.0/slick.css"},{"rel":"stylesheet","href":"https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.9.0/slick-theme.css"},{"rel":"stylesheet","href":"https://cdn.jsdelivr.net/npm/magnific-popup@1.1.0/dist/magnific-popup.min.css"},{"rel":"icon","href":"/favicon-lysibox.png"}],"style":[],"script":[{"src":"https://code.jquery.com/jquery-3.6.0.min.js","body":true},{"type":"text/javascript","src":"https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.min.js","body":true},{"type":"text/javascript","src":"https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/js/bootstrap.bundle.min.js","body":true},{"type":"text/javascript","src":"https://cdn.datatables.net/1.13.6/js/jquery.dataTables.js","body":true},{"src":"https://cdn.jsdelivr.net/npm/magnific-popup@1.1.0/dist/jquery.magnific-popup.min.js","body":true}],"noscript":[],"charset":"utf-8","viewport":"width=device-width, initial-scale=1"}

export const appPageTransition = {"name":"page","mode":"out-in"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false