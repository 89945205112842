import { default as commandessaZBsXlzMkMeta } from "C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/pages/activite/commandes.vue?macro=true";
import { default as _91id_93SiShLSHTuxMeta } from "C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/pages/activite/détails-commande/[id].vue?macro=true";
import { default as devisSNtQQ3GFWNMeta } from "C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/pages/activite/devis.vue?macro=true";
import { default as vehiculesiGAyauZ527Meta } from "C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/pages/activite/vehicules.vue?macro=true";
import { default as indexFidL4EZ5FqMeta } from "C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/pages/Catalogue/index.vue?macro=true";
import { default as liste_45produitsbjUaYnJehkMeta } from "C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/pages/Catalogue/liste/liste-produits.vue?macro=true";
import { default as _91tab_93fjaLRFNfcYMeta } from "C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/pages/Catalogue/mon-vehicule/[tab].vue?macro=true";
import { default as commandetfj45GfBdOMeta } from "C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/pages/commande.vue?macro=true";
import { default as devisDFkBvskBB6Meta } from "C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/pages/devis.vue?macro=true";
import { default as finalize_45orderAUBJptU0JyMeta } from "C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/pages/finalize-order.vue?macro=true";
import { default as indexPizQMR88bnMeta } from "C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/pages/index.vue?macro=true";
import { default as loginm20lOB1DyKMeta } from "C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/pages/login.vue?macro=true";
import { default as _91tab_93hOMjcRDR7BMeta } from "C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/pages/my-account/[tab].vue?macro=true";
import { default as paiementpx1uC4Qd5lMeta } from "C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/pages/paiement.vue?macro=true";
import { default as panierlsBrxBnrMhMeta } from "C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/pages/panier.vue?macro=true";
import { default as retrait_45articlesnrPLplOAFlMeta } from "C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/pages/retrait-articles.vue?macro=true";
import { default as _91tab_93kv7NhEc4wkMeta } from "C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/pages/store/[menuItem]/detail/[tab].vue?macro=true";
import { default as _91tab_93qbvp3rLpqJMeta } from "C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/pages/store/[tab].vue?macro=true";
import { default as tableau_45de_45bordm1Il0j73WEMeta } from "C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/pages/tableau-de-bord.vue?macro=true";
import { default as indexkJUEOzTaliMeta } from "C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/pages/test/index.vue?macro=true";
export default [
  {
    name: commandessaZBsXlzMkMeta?.name ?? "activite-commandes",
    path: commandessaZBsXlzMkMeta?.path ?? "/activite/commandes",
    meta: commandessaZBsXlzMkMeta || {},
    alias: commandessaZBsXlzMkMeta?.alias || [],
    redirect: commandessaZBsXlzMkMeta?.redirect,
    component: () => import("C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/pages/activite/commandes.vue").then(m => m.default || m)
  },
  {
    name: _91id_93SiShLSHTuxMeta?.name ?? "activite-détails-commande-id",
    path: _91id_93SiShLSHTuxMeta?.path ?? "/activite/d%C3%A9tails-commande/:id()",
    meta: _91id_93SiShLSHTuxMeta || {},
    alias: _91id_93SiShLSHTuxMeta?.alias || [],
    redirect: _91id_93SiShLSHTuxMeta?.redirect,
    component: () => import("C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/pages/activite/détails-commande/[id].vue").then(m => m.default || m)
  },
  {
    name: devisSNtQQ3GFWNMeta?.name ?? "activite-devis",
    path: devisSNtQQ3GFWNMeta?.path ?? "/activite/devis",
    meta: devisSNtQQ3GFWNMeta || {},
    alias: devisSNtQQ3GFWNMeta?.alias || [],
    redirect: devisSNtQQ3GFWNMeta?.redirect,
    component: () => import("C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/pages/activite/devis.vue").then(m => m.default || m)
  },
  {
    name: vehiculesiGAyauZ527Meta?.name ?? "activite-vehicules",
    path: vehiculesiGAyauZ527Meta?.path ?? "/activite/vehicules",
    meta: vehiculesiGAyauZ527Meta || {},
    alias: vehiculesiGAyauZ527Meta?.alias || [],
    redirect: vehiculesiGAyauZ527Meta?.redirect,
    component: () => import("C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/pages/activite/vehicules.vue").then(m => m.default || m)
  },
  {
    name: indexFidL4EZ5FqMeta?.name ?? "Catalogue",
    path: indexFidL4EZ5FqMeta?.path ?? "/Catalogue",
    meta: indexFidL4EZ5FqMeta || {},
    alias: indexFidL4EZ5FqMeta?.alias || [],
    redirect: indexFidL4EZ5FqMeta?.redirect,
    component: () => import("C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/pages/Catalogue/index.vue").then(m => m.default || m)
  },
  {
    name: liste_45produitsbjUaYnJehkMeta?.name ?? "Catalogue-liste-liste-produits",
    path: liste_45produitsbjUaYnJehkMeta?.path ?? "/Catalogue/liste/liste-produits",
    meta: liste_45produitsbjUaYnJehkMeta || {},
    alias: liste_45produitsbjUaYnJehkMeta?.alias || [],
    redirect: liste_45produitsbjUaYnJehkMeta?.redirect,
    component: () => import("C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/pages/Catalogue/liste/liste-produits.vue").then(m => m.default || m)
  },
  {
    name: _91tab_93fjaLRFNfcYMeta?.name ?? "Catalogue-mon-vehicule-tab",
    path: _91tab_93fjaLRFNfcYMeta?.path ?? "/Catalogue/mon-vehicule/:tab()",
    meta: _91tab_93fjaLRFNfcYMeta || {},
    alias: _91tab_93fjaLRFNfcYMeta?.alias || [],
    redirect: _91tab_93fjaLRFNfcYMeta?.redirect,
    component: () => import("C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/pages/Catalogue/mon-vehicule/[tab].vue").then(m => m.default || m)
  },
  {
    name: commandetfj45GfBdOMeta?.name ?? "commande",
    path: commandetfj45GfBdOMeta?.path ?? "/commande",
    meta: commandetfj45GfBdOMeta || {},
    alias: commandetfj45GfBdOMeta?.alias || [],
    redirect: commandetfj45GfBdOMeta?.redirect,
    component: () => import("C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/pages/commande.vue").then(m => m.default || m)
  },
  {
    name: devisDFkBvskBB6Meta?.name ?? "devis",
    path: devisDFkBvskBB6Meta?.path ?? "/devis",
    meta: devisDFkBvskBB6Meta || {},
    alias: devisDFkBvskBB6Meta?.alias || [],
    redirect: devisDFkBvskBB6Meta?.redirect,
    component: () => import("C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/pages/devis.vue").then(m => m.default || m)
  },
  {
    name: finalize_45orderAUBJptU0JyMeta?.name ?? "finalize-order",
    path: finalize_45orderAUBJptU0JyMeta?.path ?? "/finalize-order",
    meta: finalize_45orderAUBJptU0JyMeta || {},
    alias: finalize_45orderAUBJptU0JyMeta?.alias || [],
    redirect: finalize_45orderAUBJptU0JyMeta?.redirect,
    component: () => import("C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/pages/finalize-order.vue").then(m => m.default || m)
  },
  {
    name: indexPizQMR88bnMeta?.name ?? "index",
    path: indexPizQMR88bnMeta?.path ?? "/",
    meta: indexPizQMR88bnMeta || {},
    alias: indexPizQMR88bnMeta?.alias || [],
    redirect: indexPizQMR88bnMeta?.redirect,
    component: () => import("C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginm20lOB1DyKMeta?.name ?? "login",
    path: loginm20lOB1DyKMeta?.path ?? "/login",
    meta: loginm20lOB1DyKMeta || {},
    alias: loginm20lOB1DyKMeta?.alias || [],
    redirect: loginm20lOB1DyKMeta?.redirect,
    component: () => import("C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/pages/login.vue").then(m => m.default || m)
  },
  {
    name: _91tab_93hOMjcRDR7BMeta?.name ?? "my-account-tab",
    path: _91tab_93hOMjcRDR7BMeta?.path ?? "/my-account/:tab()",
    meta: _91tab_93hOMjcRDR7BMeta || {},
    alias: _91tab_93hOMjcRDR7BMeta?.alias || [],
    redirect: _91tab_93hOMjcRDR7BMeta?.redirect,
    component: () => import("C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/pages/my-account/[tab].vue").then(m => m.default || m)
  },
  {
    name: paiementpx1uC4Qd5lMeta?.name ?? "paiement",
    path: paiementpx1uC4Qd5lMeta?.path ?? "/paiement",
    meta: paiementpx1uC4Qd5lMeta || {},
    alias: paiementpx1uC4Qd5lMeta?.alias || [],
    redirect: paiementpx1uC4Qd5lMeta?.redirect,
    component: () => import("C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/pages/paiement.vue").then(m => m.default || m)
  },
  {
    name: panierlsBrxBnrMhMeta?.name ?? "panier",
    path: panierlsBrxBnrMhMeta?.path ?? "/panier",
    meta: panierlsBrxBnrMhMeta || {},
    alias: panierlsBrxBnrMhMeta?.alias || [],
    redirect: panierlsBrxBnrMhMeta?.redirect,
    component: () => import("C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/pages/panier.vue").then(m => m.default || m)
  },
  {
    name: retrait_45articlesnrPLplOAFlMeta?.name ?? "retrait-articles",
    path: retrait_45articlesnrPLplOAFlMeta?.path ?? "/retrait-articles",
    meta: retrait_45articlesnrPLplOAFlMeta || {},
    alias: retrait_45articlesnrPLplOAFlMeta?.alias || [],
    redirect: retrait_45articlesnrPLplOAFlMeta?.redirect,
    component: () => import("C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/pages/retrait-articles.vue").then(m => m.default || m)
  },
  {
    name: _91tab_93kv7NhEc4wkMeta?.name ?? "store-menuItem-detail-tab",
    path: _91tab_93kv7NhEc4wkMeta?.path ?? "/store/:menuItem()/detail/:tab()",
    meta: _91tab_93kv7NhEc4wkMeta || {},
    alias: _91tab_93kv7NhEc4wkMeta?.alias || [],
    redirect: _91tab_93kv7NhEc4wkMeta?.redirect,
    component: () => import("C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/pages/store/[menuItem]/detail/[tab].vue").then(m => m.default || m)
  },
  {
    name: _91tab_93qbvp3rLpqJMeta?.name ?? "store-tab",
    path: _91tab_93qbvp3rLpqJMeta?.path ?? "/store/:tab()",
    meta: _91tab_93qbvp3rLpqJMeta || {},
    alias: _91tab_93qbvp3rLpqJMeta?.alias || [],
    redirect: _91tab_93qbvp3rLpqJMeta?.redirect,
    component: () => import("C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/pages/store/[tab].vue").then(m => m.default || m)
  },
  {
    name: tableau_45de_45bordm1Il0j73WEMeta?.name ?? "tableau-de-bord",
    path: tableau_45de_45bordm1Il0j73WEMeta?.path ?? "/tableau-de-bord",
    meta: tableau_45de_45bordm1Il0j73WEMeta || {},
    alias: tableau_45de_45bordm1Il0j73WEMeta?.alias || [],
    redirect: tableau_45de_45bordm1Il0j73WEMeta?.redirect,
    component: () => import("C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/pages/tableau-de-bord.vue").then(m => m.default || m)
  },
  {
    name: indexkJUEOzTaliMeta?.name ?? "test",
    path: indexkJUEOzTaliMeta?.path ?? "/test",
    meta: indexkJUEOzTaliMeta || {},
    alias: indexkJUEOzTaliMeta?.alias || [],
    redirect: indexkJUEOzTaliMeta?.redirect,
    component: () => import("C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/pages/test/index.vue").then(m => m.default || m)
  }
]