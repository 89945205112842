import { defineStore } from "pinia";

// Config
const siteName = "GRERARD AUTOMOBILE";
const siteRegion = "GIMONT (32)";
const sitePhone = "05 62 67 88 20";
const logo = "/img/logos/APP-logo-p3-v1.png"

export const useAppStore = defineStore("app", {
	state: () => ({
		tokenSite: null,
		mobile_width: 1100,
		showMenu: false,
		showQuote: false,
		currentEtablissement: {
			siteId: 1,
			templateId: 0,
			theme: {
				light: {
					mainColor: "",
					secondaryColor: "",
					terciaryColor: "",
					neutral23: "",
					shades76: "",
					neutral45: "",
					neutral71: "",
					neutral77: "",
					shades94: "",
					borderBtnWhite: "",
					background97: "",
					btnSearch : "", //"linear-gradient(148.9deg, #0445ED 1.79%, #419AFC 98.72%)",
					btnLinear : "", //"line	ar-gradient(148.9deg, #0445ED 1.79%, #419AFC 98.72%)"
					dispo: "#4FAE40",
					dispoSoon: "#FFE073",
					indispo: "#DDE2E8"
				},
				// dark: {
				// 	mainColor: "black",
				// 	alternColor: "black",
				// 	invertColor: "black",
				// 	backColor: "black",
				// },
			},
			navbarApp: [
				{
					idCategory: 1,
					nameCategory: "Tableau de bord",
					imageCategory: "/img/icons/navbar/tableau_de_bord.png",
					fontAwesome: "",
					link: "/tableau-de-bord",
					// link: "/test",
					disabled: false,
					subCategories: [],
				},
				{
					idCategory: 2,
					nameCategory: "Mon activité",
					imageCategory: "/img/icons/navbar/edit.png",
					fontAwesome: "",
					link: "/activite",
					subCategories: [
						{
							idSubCategory: 3,
							nameSubCategory: "Mes véhicules",
							linkSubCategory: "/activite/vehicules",

						},
						{
							idSubCategory: 4,
							nameSubCategory: "Mes commandes",
							linkSubCategory: "/activite/commandes",
						},
						{
							idSubCategory: 5,
							nameSubCategory: "Mes devis",
							linkSubCategory: "/activite/devis",
							disabled: true
						},
						{
							idSubCategory: 6,
							nameSubCategory: "Mes clients",
							linkSubCategory: "",
							disabled: true
						},
						{
							idSubCategory: 7,
							nameSubCategory: "Mes rendez-vous",
							linkSubCategory: "",
							disabled: true

						}
					]
				},
				{
					idCategory: 8,
					nameCategory: "Catalogue",
					imageCategory: "/img/icons/navbar/catalogue.png",
					alternativeImageCategory: "",
					link: "/catalogue",
					subCategories: []
				},
				{
					idCategory: 9,
					nameCategory: "Boutique",
					imageCategory: "/img/icons/navbar/shop.png",
					alternativeImageCategory: "",
					link: "/store/maintenance?menu=demarrage-batterie",
					subCategories: [],
				},
				{
					idCategory: 10,
					nameCategory: "Pneumatique",
					imageCategory: "/img/icons/navbar/pneumatique.png",
					alternativeImageCategory: "",
					link: "/tyres",
					subCategories: [],
				},
				{
					idCategory: 11,
					nameCategory: "Gestion atelier",
					imageCategory: "/img/icons/navbar/gestionAtelier.png",
					alternativeImageCategory: "",
					link: "/gestion-atelier",
					diabled: true,
					subCategories: [
						{
							idSubCategory: 12,
							nameSubCategory: "Planning rendez-vous",
							linkSubCategory: "",
							disabled: true

						},
						{
							idSubCategory: 13,
							nameSubCategory: "Ordre de mission",
							linkSubCategory: "",
							disabled: true

						},
						{
							idSubCategory: 14,
							nameSubCategory: "Suivi des heures facturées",
							linkSubCategory: "",
							disabled: true

						}
					],
				},
				{
					idCategory: 15,
					nameCategory: "Mon site internet",
					imageCategory: "/img/icons/navbar/home.png",
					alternativeImageCategory: "",
					link: "/my-site",
					diabled: true,
					subCategories: [
						{
							idSubCategory: 16,
							nameSubCategory: "Actualités",
							linkSubCategory: "",
							disabled: true

						},
						{
							idSubCategory: 17,
							nameSubCategory: "Promotions",
							linkSubCategory: "",
							disabled: true

						},
						{
							idSubCategory: 18,
							nameSubCategory: "Diaporama",
							linkSubCategory: "",
							disabled: true

						},
						{
							idSubCategory: 19,
							nameSubCategory: "Véhicules d’occasion",
							linkSubCategory: " ",
							disabled: true

						},
						{
							idSubCategory: 20,
							nameSubCategory: "Recrutement",
							linkSubCategory: "",
							disabled: true

						}
					],
				},
				{
					idCategory: 21,
					nameCategory: "Statistiques",
					imageCategory: "/img/icons/navbar/plusS.png",
					alternativeImageCategory: "",
					link: "/stats",
					diabled: true,
					subCategories: [
						{
							idSubCategory: 22,
							nameSubCategory: "Plaques",
							linkSubCategory: "",
							disabled: true

						},

					],
				},
			],
			orders: [
				{
					idOrder: 1,
					ref: '2776',
					date: '09/06/2023',
					immat: 'AA-123-AA',
					total: '25,96',
					reglement: 'Carte bancaire',
					etat: 'Livrée',
				},
				{
					idOrder: 2,
					ref: '2775',
					date: '09/06/2023',
					immat: 'AA-456-BB',
					total: '25,96',
					reglement: 'Carte bancaire',
					etat: 'En attente',
				},
				{
					idOrder: 3,
					ref: '2774',
					date: '09/06/2023',
					immat: 'AA-456-BB',
					total: '81,48',
					reglement: 'Carte bancaire',
					etat: 'Prêt à expédier',
				},
				{
					idOrder: 4,
					ref: '2772',
					date: '09/06/2023',
					immat: 'AA-456-BB',
					total: '28,80',
					reglement: 'Carte bancaire',
					etat: 'Livrée',
				},
				{
					idOrder: 5,
					ref: '2771',
					date: '09/06/2023',
					immat: 'AA-456-BB',
					total: '28,80',
					reglement: 'Carte bancaire',
					etat: 'Livrée',
				},
				{
					idOrder: 6,
					ref: '2770',
					date: '09/06/2023',
					immat: 'AA-456-BB',
					total: '28,80',
					reglement: 'Carte bancaire',
					etat: 'En attente',
				},
				{
					idOrder: 7,
					ref: '2769',
					date: '09/06/2023',
					immat: 'AA-456-BB',
					total: '28,80',
					reglement: 'Carte bancaire',
					etat: 'Prêt à expédier',
				},
				{
					idOrder: 8,
					ref: '2768',
					date: '09/06/2023',
					immat: 'AA-456-BB',
					total: '28,80',
					reglement: 'Carte bancaire',
					etat: 'Livrée',
				},
			],
			vehicules: [],
			devis: [
				{
					idDevis: 1,
					ref: '2776',
					date: '09/06/2023',
					immat: 'AA-123-AA',
					model: 'Modèle du véhicule',
					dateValid: '09/06/2023',
					etat: 'Traité',
				},
				{
					idDevis: 2,
					ref: '2775',
					date: '09/06/2023',
					immat: 'AA-456-BB',
					model: 'Modèle du véhicule',
					dateValid: '09/06/2023',
					etat: 'En attente',
				},
				{
					idDevis: 3,
					ref: '2774',
					date: '09/06/2023',
					immat: 'AA-456-BB',
					model: 'Modèle du véhicule',
					dateValid: '09/06/2023',
					etat: 'En attente',
				},
				{
					idDevis: 4,
					ref: '2773',
					date: '09/06/2023',
					immat: 'AA-456-BB',
					model: 'Modèle du véhicule',
					dateValid: '09/06/2023',
					etat: 'Traité',
				},
				{
					idDevis: 5,
					ref: '2772',
					date: '09/06/2023',
					immat: 'AA-456-BB',
					model: 'Modèle du véhicule',
					dateValid: '09/06/2023',
					etat: 'Traité',
				},
				{
					idDevis: 6,
					ref: '2771',
					date: '09/06/2023',
					immat: 'AA-456-BB',
					model: 'Modèle du véhicule',
					dateValid: '09/06/2023',
					etat: 'En attente',
				},
				{
					idDevis: 7,
					ref: '2770',
					date: '09/06/2023',
					immat: 'AA-456-BB',
					model: 'Modèle du véhicule',
					dateValid: '09/06/2023',
					etat: 'En attente',
				},
				{
					idDevis: 8,
					ref: '2769',
					date: '09/06/2023',
					immat: 'AA-456-BB',
					model: 'Modèle du véhicule',
					dateValid: '09/06/2023',
					etat: 'Traité',
				},
			],
			InfoVehicule: [
				{
					idVehicule: 1,
					nomVehicule: 'Jaguar F-Type décapotable (x152)',
					descVehicule: 'SCV6 400 SPORT AWD 400cv <br> Essence Décapotable Traction intégrale',
					immat: 'AA-123-BB',
					Vin: 'XXXXX000000000000',
					imgMarque: '/img/pages/catalogue/marque.png',
				},
			],
			CatalogueCourant: [
				{
					idCourant: 1,
					nomCat: 'Freinage',
					nomSubCat: [
						{
							idnomSubCat: 2,
							nomSubCat: "Nom du produit",
							linkSubCat: "#",
							icon: true,
						},
						{
							idnomSubCat: 3,
							nomSubCat: "Nom du produit",
							linkSubCat: "#",
							icon: false,
						},
						{
							idnomSubCat: 4,
							nomSubCat: "Nom du produit",
							linkSubCat: "#",
							icon: true,

						},
					],
					imgCat: '/img/pages/catalogue/courant/freinage.png',
				},
				{
					idCourant: 5,
					nomCat: 'Filtration',
					nomSubCat: [
						{
							idnomSubCat: 6,
							nomSubCat: "Nom du produit",
							linkSubCat: "/catalogue/liste/liste-produits",
							icon: false,

						},
						{
							idnomSubCat: 7,
							nomSubCat: "Nom du produit",
							linkSubCat: "#",
							icon: false,

						},
						{
							idnomSubCat: 8,
							nomSubCat: "Nom du produit",
							linkSubCat: "#",
							icon: false,

						},
					],
					imgCat: '/img/pages/catalogue/courant/filtration.png',
				},
				{
					idCourant: 9,
					nomCat: 'Courroies et distribution',
					nomSubCat: [
						{
							idnomSubCat: 10,
							nomSubCat: "Nom du produit",
							linkSubCat: "#",
							icon: true,

						},
						{
							idnomSubCat: 11,
							nomSubCat: "Nom du produit",
							linkSubCat: "#",
							icon: false,

						},
						{
							idnomSubCat: 12,
							nomSubCat: "Nom du produit",
							linkSubCat: "#",
							icon: true,

						},
					],
					imgCat: '/img/pages/catalogue/courant/distribution.png',
				},

			],
			CataloguePiece: [],
			FiltreMarquesVehicules: [
				{
					idMarque: 1,
					nomMarque: 'Peugeot',
				},
				{
					idMarque: 2,
					nomMarque: 'Citroën',
				},
				{
					idMarque: 3,
					nomMarque: 'Renault',
				},
				{
					idMarque: 4,
					nomMarque: 'Opel',
				},
				{
					idMarque: 5,
					nomMarque: 'Volkswagen',
				},
				{
					idMarque: 6,
					nomMarque: 'Mercedes',
				},
				{
					idMarque: 7,
					nomMarque: 'Mercedes',
				},
				{
					idMarque: 8,
					nomMarque: 'Audi',
				},
				{
					idMarque: 9,
					nomMarque: 'BMW',
				},
			],
			ListingProduit: [
				{
					idProduit: 1,
					// promoProduit: true,
					imgProduit: '/img/pages/catalogue/fakephoto.png',
					nomProduit: 'Filtre à air',
					stockProduit: 'Disponible',
					MarqueProduit: 'MARQUE ',
					refProduit: 'A1792',
					ParticulariteProduit: 'Cartouche filtrante',
					lProduit: '239',
					LProduit: '205',
					HProduit: '53',
					logoProduit: '/img/pages/catalogue/fakelogo.png',
					// prixHtRemise: '13.98',
					prixHtnonRemise: '13,98',
					prixHtRemise: '15.99',
					prixClient: '',
					actionProduit: [
						{
							idAction: 2,
							nomAction: 'Remplacement du filtre à air',
							tarifAction: '24,00',
							totalHtnonRemise: '25,00',
							totalHtRemise: '39.99'
						},
					],
					totProduit: '',
				},
				{
					idProduit: 3,
					// promoProduit: true,
					imgProduit: '/img/pages/catalogue/fakephoto.png',
					nomProduit: 'Filtre à air',
					stockProduit: 'Précommande',
					MarqueProduit: 'MARQUE ',
					refProduit: 'A1792',
					ParticulariteProduit: 'Cartouche filtrante',
					lProduit: '239',
					LProduit: '205',
					HProduit: '53',
					logoProduit: '/img/pages/catalogue/fakelogo.png',
					// prixHtRemise: '13.98',
					prixHtnonRemise: '13,98',
					prixHtRemise: '15.99',
					prixClient: '',
					actionProduit: [
						{
							idAction: 4,
							nomAction: 'Remplacement du filtre à air',
							tarifAction: '24,00',
							totalHtnonRemise: '25,00',
							totalHtRemise: '39.99'
						},
					],
					totProduit: '',
				},
				{
					idProduit: 5,
					// promoProduit: true,
					imgProduit: '/img/pages/catalogue/fakephoto.png',
					nomProduit: 'Filtre à air',
					stockProduit: 'Non disponible',
					MarqueProduit: 'MARQUE ',
					refProduit: 'A1792',
					ParticulariteProduit: 'Cartouche filtrante',
					lProduit: '239',
					LProduit: '205',
					HProduit: '53',
					logoProduit: '/img/pages/catalogue/fakelogo.png',
					// prixHtRemise: '13.98',
					prixHtnonRemise: '13,98',
					prixHtRemise: '15.99',
					prixClient: '',
					actionProduit: [
						{
							idAction: 6,
							nomAction: 'Remplacement du filtre à air',
							tarifAction: '24,00',
							totalHtnonRemise: '25,00',
							totalHtRemise: '39.99'
						},
					],
					totProduit: '',
				},
			],
			siteName: "Mecanobox",
			logo: "",
			socials: {
				facebook: "",
				twitter: "",
				instagram: "",
				tiktok: "",
				youtube: "",
				daylimotion: "",
				twitch: "",
				whatsapp: "",
			},
			contactInformation: {
				address: "RUE DE LA TUILERIE",
				city: "GIMONT",
				zipcode: "32200",
				country: "FRANCE",
				primaryPhone: "05 62 67 88 20",
				phones: [],
				primaryEmail: "contact@gerardauto.fr",
				emails: [],
				gps: {
					latitude: "",
					longitude: "",
					altitude: "",
				},
			},
			seo: {
				describe: "Mecanobox descriptionss",
				sharedImage: "",
				title: "AutopartsBox",
				keywords: "gara, mécanicien, vente en ligne",
				favicon: "",
				googleVerification: "",
			},
			htSite: true,
			actualVisitCatalogue: "",
			isCheckStockBeforeOrder: true
		},
		currentTemplate: {
			id: 0,
			name: "Default Template",
			template: {
				header: {
					name: "DefaultHeader",
				},
				navbar: {
					name: "DefaultNavBar",
				},
				home: [
					// {
					// 	name: "SearcheVehiculeDefault",
					// 	type_name: "SearchVehicule",
					// },
					// {
					// 	name: "DefaultThematic",
					// 	type_name: "Thematic",
					// },
					// {
					// 	name: "DefaultSlideModule",
					// 	type_name: "Slide",
					// },
					// {
					// 	name: "DefaultSiteMapContactModule",
					// 	type_name: "SiteMapContact",
					// },
				],
				contact: [
					{
						name: "DefaultContact",
						type_name: "Contact",
					},
				],
				footer: {
					name: "DefaultFooter",
				},
				fixedButton: {
					name: "FixedBarButton",
				},
			},
		},
		currentDashbord: {
			id: 0,
			name: "Default Template",
			template: {
				header: {
					name: "DefaultHeader",
				},
				navbar: {
					name: "DefaultAppNavbar",
				},
				home: [
					// {
					// 	name: "SearcheVehiculeDefault",
					// 	type_name: "SearchVehicule",
					// },
					// {
					// 	name: "DefaultThematic",
					// 	type_name: "Thematic",
					// },
					// {
					// 	name: "DefaultSlideModule",
					// 	type_name: "Slide",
					// },
					// {
					// 	name: "DefaultSiteMapContactModule",
					// 	type_name: "SiteMapContact",
					// },
				],
				contact: [
					{
						name: "DefaultContact",
						type_name: "Contact",
					},
				],
				footer: {
					name: "DefaultFooter",
				},
				fixedButton: {
					name: "FixedBarButton",
				},
			},
		},
		currentCart: [
			{
				idProduit: 1,
				// promoProduit: true,
				imgProduit: '/img/pages/catalogue/fakephoto.png',
				nomProduit: 'Filtre à air',
				stockProduit: 'Disponible',
				MarqueProduit: 'MARQUE ',
				refProduit: 'A1792',
				ParticulariteProduit: 'Cartouche filtrante',
				lProduit: '239',
				LProduit: '205',
				HProduit: '53',
				logoProduit: '/img/pages/catalogue/fakelogo.png',
				// prixHtRemise: '13.98',
				prixHtnonRemise: '13,98',
				prixHtRemise: '15.99',
				prixClient: '',
				actionProduit: [
					{
						idAction: 2,
						nomAction: 'Remplacement du filtre à air',
						tarifAction: '24,00',
						totalHtnonRemise: '25,00',
						totalHtRemise: '39.99'
					},
				],
				totProduit: '',
			},
			{
				idProduit: 2,
				// promoProduit: true,
				imgProduit: '/img/pages/catalogue/fakephoto.png',
				nomProduit: 'Filtre à air',
				stockProduit: 'Disponible',
				MarqueProduit: 'MARQUE ',
				refProduit: 'A1792',
				ParticulariteProduit: 'Cartouche filtrante',
				lProduit: '239',
				LProduit: '205',
				HProduit: '53',
				logoProduit: '/img/pages/catalogue/fakelogo.png',
				// prixHtRemise: '13.98',
				prixHtnonRemise: '13,98',
				prixHtRemise: '15.99',
				prixClient: '',
				actionProduit: [
					{
						idAction: 2,
						nomAction: 'Remplacement du filtre à air',
						tarifAction: '24,00',
						totalHtnonRemise: '25,00',
						totalHtRemise: '39.99'
					},
				],
				totProduit: '',
			},
		],
		infoCar: {
			lastKType: "",
			lastImmat: "",
			actualKType: "",
			actualImmat: "",
			carGenericDatas: [],
			carPlateDatas: [],
			lastVin: "",
			actualVin: ""
		},
		shopMenu: [
			{
				menuItem : "demarrage-batterie",
				item : [
					{
						link: "ampoule-eclairage",
						text: "Ampoules d'éclairage"
					},
					{
						link: "batterie-auto",
						text: "Batterie auto"
					},
					{
						link: "chargeur-booster-batteries",
						text: "Chargeur, booster batteries"
					},
					{
						link: "cable-demarrage",
						text: "Câble de démarrage"
					},
					{
						link: "batterie-camping-car",
						text: "Batterie camping car, poids lourd, agricole"
					},
					{
						link: "batteries-moto",
						text: "Batteries moto"
					},
					{
						link: "commutateurs-relais",
						text: "Commutateurs relais"
					},
					{
						link: "cosses-boitiers",
						text: "Cosses et boîtiers"
					},
					{
						link: "cable-electrique",
						text: "Câble électrique"
					},
					{
						link: "demarrage-batterie",
						text: "Démarrage, batterie"
					}
				]
			},
			{
				menuItem: "atelier-hygiene",
				item: [
					{
						link: "hygiene-protection-sanitaire",
						text: "Hygiène et protection sanitaire"
					},
					{
						link: "savon-hygiene-mains",
						text: "Savon, hygiène des mains"
					},
					{
						link: "sacs-poubelle-supports",
						text: "Sacs poubelle et supports"
					},
					{
						link: "hygiene-alimentaire",
						text: "Hygiène alimentaire"
					},
					{
						link: "nettoyant-vitre",
						text: "Nettoyant vitre"
					}
				]
			},
			{
				menuItem: "atelier-consommable",
				item: [
					{
						link: "cartouche-pot-de-graisse",
						text: "Cartouche, pot de graisse"
					},
					{
						link: "nettoyant-freins-degrippant",
						text: "Nettoyant freins, dégrippant"
					},
					{
						link: "consommables-atelier",
						text: "Consommables Atelier"
					},
					{
						link: "absorbant-huile-eau-hydrocarbure",
						text: "Absorbant huile, eau, hydrocarbure"
					},
					{
						link: "frein-filet",
						text: "Frein filet"
					},
					{
						link: "atelier-consommables",
						text: "Atelier - Consommables"
					}
				]
			},
			{
				menuItem: "huile-liquides",
				item: [
					{
						link: "huile-moteur",
						text: "Huile moteur"
					},
					{
						link: "liquide-de-refroidissement",
						text: "Liquide de refroidissement"
					},
					{
						link: "liquide-frein",
						text: "Liquide frein (Dot 4 - 5-1 - LHM)"
					},
					{
						link: "huile-boite-de-vitesses",
						text: "Huile pour boîte de vitesses"
					},
					{
						link: "huile-liquides",
						text: "Huile, liquides"
					}
				]
			},
			{
				menuItem: "peinture",
				item: [
					{
						link: "mastic-carrosserie",
						text: "Mastic carrosserie"
					},
					{
						link: "esthetique-correction-peinture",
						text: "Esthétique, correction peinture"
					},
					{
						link: "pistolet-a-peinture",
						text: "Pistolet à peinture"
					},
					{
						link: "peinture",
						text: "Peinture"
					},
					{
						link: "mastic-bandage-echappement",
						text: "Mastic et bandage d'échappement"
					}
				]
			},
			{
				menuItem: "additif-moteur",
				item: [
					{
						link: "a-remplir",
						text: "A remplir"
					}
				]
			}
		],
		auth: {
			token: "",
			dateStart: "",
			dateEnd: "",
			userToken: "",
			dateEndUserToken: ""
		},
		userConnected: {
			userId: 10, // 1
			userEmail: "demo@autopartspro.fr" // s.bravi@graphibox.net
		}
	})
});