import revive_payload_client_Jux6fPFH5S from "C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/node_modules/.pnpm/nuxt@3.10.0_sass@1.70.0_vite@5.0.12/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_POaAqCLlIl from "C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/node_modules/.pnpm/nuxt@3.10.0_sass@1.70.0_vite@5.0.12/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_QAmKExe1uZ from "C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/node_modules/.pnpm/nuxt@3.10.0_sass@1.70.0_vite@5.0.12/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_x19QpOKzQm from "C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/node_modules/.pnpm/nuxt@3.10.0_sass@1.70.0_vite@5.0.12/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_V6qNckPpgO from "C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/node_modules/.pnpm/nuxt@3.10.0_sass@1.70.0_vite@5.0.12/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_J8J5QrXfDA from "C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/node_modules/.pnpm/@pinia+nuxt@0.4.11_vue@3.4.15/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/.nuxt/components.plugin.mjs";
import prefetch_client_i9bIFQmYNw from "C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/node_modules/.pnpm/nuxt@3.10.0_sass@1.70.0_vite@5.0.12/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_dJeSnh3jcp from "C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/node_modules/.pnpm/@graphiboxdev+graphi-toolbox@1.1.424_vue@3.4.15/node_modules/@graphiboxdev/graphi-toolbox/dist/runtime/plugin.mjs";
import pinia_600p1CnTBM from "C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/node_modules/.pnpm/@graphiboxdev+graphi-toolbox@1.1.424_vue@3.4.15/node_modules/@graphiboxdev/graphi-toolbox/dist/runtime/pinia.mjs";
import chunk_reload_client_Jd5Abhr2qV from "C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/node_modules/.pnpm/nuxt@3.10.0_sass@1.70.0_vite@5.0.12/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
export default [
  revive_payload_client_Jux6fPFH5S,
  unhead_POaAqCLlIl,
  router_QAmKExe1uZ,
  payload_client_x19QpOKzQm,
  check_outdated_build_client_V6qNckPpgO,
  plugin_vue3_J8J5QrXfDA,
  components_plugin_KR1HBZs4kY,
  prefetch_client_i9bIFQmYNw,
  plugin_dJeSnh3jcp,
  pinia_600p1CnTBM,
  chunk_reload_client_Jd5Abhr2qV
]