<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    
      <!-- Modal Erreur de connexion 
      <div class="modal fade" id="myModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">
                Erreur
              </h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="d-flex flex-column align-items-center">
                <div class="imgError500">
                  <img class="w-100" src="/img/error-500.jpg" alt="" />
                </div>
                <span>Echec de connexions aux serveurs.</span>
              </div>
              <p class="mt-3">
                Nos serveurs sont actuellement indisponibles, veuillez réssayer dans quelques instants,
                Si le problème persiste veuillez contactez notre support sur <a href="#">graphibox@support.biz</a>
              </p>
            </div>
            <div class="modal-footer">

            </div>
          </div>
        </div>
      </div>-->
  </div>
</template>
<script setup>
import { useAppStore } from "@/store/appStore.js"
import { useThemeColor } from "@/composables/useThemeColor.js";



const appStore = useAppStore()
// Cart
const { initializeCart } = useCart();
initializeCart(appStore.currentEtablissement.htSite);

// Color site
onMounted(async () => {
  useThemeColor(appStore.currentEtablissement.theme.light);
});
</script>


<style scoped>
#exampleModalLabel {
  font-family: Inter;
  color: red;
}
span {
  font-family: Inter;
}
p {
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  color: #3B393D;
}
.imgError500 {
  width: 60%;
}

/*#modalSpinner .modal-content {*/
/*  background-color: transparent;*/
/*  border: none;*/
/*}*/

.spinner-grow {
  width: 3rem;
  height: 3rem;
  background-color: #F0FAF8;
}
</style>
