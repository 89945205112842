
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "b3df6696-2a57-45ce-955c-a64bc57a97a8"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, inlineConfig)
