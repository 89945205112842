import validate from "C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/node_modules/.pnpm/nuxt@3.10.0_sass@1.70.0_vite@5.0.12/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/node_modules/.pnpm/nuxt@3.10.0_sass@1.70.0_vite@5.0.12/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/middleware/auth.ts"),
  "auth-user": () => import("C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/middleware/authUser.ts"),
  "read-cookies": () => import("C:/Users/GraphiDev_U3/Documents/_CLIENTS/App/middleware/readCookies.ts")
}